import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Text } from '../../../components/Text';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { formatCurrency, formatPercent } from '../../../utils/formatters';

export const ConfirmCreateDepositOptions = observer(() => {
    return (
        <Box>
            <Text>Нажимая на кнопку "подтвердить" я подтверждаю что:</Text><br/>
            <Text>— сумма моих инвестиций в рамках данного продукта составляет {formatCurrency(depositCalculationsStore.sum)}</Text>
            <Text>— срок инвестиции составляет {depositCalculationsStore.duration} мес.</Text>
            <Text>— сумма ежемесячного пополнения составляет {formatCurrency(depositCalculationsStore.replenishment)}</Text>
            <Text>— доля в прибыли в рамках данного инвестиционного продукта составляет {formatPercent(depositCalculationsStore.interval?.sharePercent)}</Text>
            <Text>— понимаю, что инвестирование сопряжено с рисками и может привести к потере капитала в рамках суммы инвестиций</Text>
        </Box>
    );
});
